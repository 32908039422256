
export const message = {
    en: {
        title: 'Mi Enterprise Services Open Platform',
        // 按钮成为开发者
        btnJoin: 'Join as a developer',
        // btnSDK: '下载SDK',
        btnSDK: 'hide',
        joinText: 'Join us to discover and create the future together',
        // 开放能力
        openness: {
            title: 'Openness',
            titleDesc: 'Leading capability on providing mobile phone hardware and software',

            deviceTitle: 'Manage Devices',
            deviceDdesc: 'Flexible control of main devices',
            wifi: 'Wi-Fi',
            bluetooth: 'Bluetooth',
            VPN: 'VPN',
            NFC: 'NFC',
            GPS: 'GPS',
            camera: 'Camera',
            microphone: 'Microphone',
            SDCard: 'SD card',
            fingerprintSensor: 'Fingerprint sensor',
            USB: 'USB',
            dataNetwork: 'Data network',
            OTG: 'OTG',
            screenshot: 'Screenshot',
            accelerometer: 'Accelerometer',
            debug: 'Debug',

            appTitle: 'Manage Apps',
            appDdesc: 'With the powerful app management capability, you can install and use apps easily',
            app1: 'App installation blocklist/exceptions',
            app2: 'App running blocklist/exceptions',
            app3: 'App permissions',
            app4: 'Prevent apps from process kill',
            app5: 'Install/uninstall apps remotely and silently',
            app6: 'Website and domain blocklist/exceptions',

            dataTitle: 'Data & Security',
            dataDdesc: 'Data security and leak prevention',
            data1: 'Restricted root access',
            data2: 'Protection from flashing',
            data3: 'Remote device lock',
            data4: 'Remote data erasure',
            data5: 'Remote positioning',
            data6: 'Global sensitive word monitoring',

            callTitle: 'Communication Control',
            callDesc: 'Real-time monitoring and recording of calls and info',
            call1: 'Automatic call recording',
            call2: 'Hiding phone number',
            call3: 'Control over sent and received messages',
            call4: 'Control over calls',

            customTitle: 'Customization',
            customDesc: 'Customized for your industry',
            custom1: 'Splash screen animation',
            custom2: 'Operating system',
            custom3: 'Mobile phone themes',
            custom4: 'Preinstalled apps',
            custom5: 'Engraved company logo',
            more: '* Contact us to learn more',
        },
        // 应用场景
        scenarios: {
            title: 'Scenarios',
            titleDesc: 'Customized solutions for all kinds of industries',

            marketSalesTitle: 'Market sales',
            marketSalesDesc:
                'Covers business sales scenarios, implements customer number hiding, call recording, employee positioning and other control methods in order to ensure the security of customer information and company data.',

            smartHospitalTitle: 'Smart hospital',
            smartHospitalDesc:
                'Achieves across-hospital collaboration and improves medical services through the distribution of information, cloud positioning, one-click calling, secure information transmission and other remote deployment of medical resources.',

            educationalTitle: 'Informatization of educational administration',
            educationalDesc:
                'Controls service information, supervises home-school communication, realizes cloud teaching and research, etc. in order to comprehensively improve the teaching level.',

            smartOfficeTitle: 'Smart office',
            smartOfficeDesc:
                "Focuses on collaborative office, mobile office and other scenarios, deeply controls call behaviors, app usage behaviors, behaviors of device's access to Internet, and configures basic software and hardware settings for devices on the Cloud.",
        },
        // 优势
        advantage: {
            title: 'Our advantages',
            titleDesc: 'Leading brand and professional technology',
            title1: 'Industry-leading',
            text1: 'ROM customization capabilities',
            title2: 'Mature and reliable',
            text2: 'Service system support',
            title3: 'Easy-to-use and convenient',
            text3: 'Development kit',
            title4: 'Rich and open',
            text4: 'Product ecology',
            title5: 'Excellent',
            text5: 'Brand impact',
        },

        // 步骤
        process: {
            title: 'Access process',
            titleDesc: 'We offer a wide range of industry solutions and quick access to openness capabilities for core scenarios',
            step1: 'Complete the developer verification',
            step2: 'Download the SDK and ROM and integrate them into the solution',
            step3: 'Develop a program',
            step4: 'Submit a program',
            step5: 'Integrate into ROM',
        },

        partners: {
            title: 'Partners',
            titleDesc: 'Work and win together',
        },
    },
    cn: {
        title: '小米政企服务开放平台',
        // 按钮成为开发者
        btnJoin: '成为开发者',
        btnSDK: '下载SDK',
        joinText: '加入我们，一起，探索黑科技',
        // 开放能力
        openness: {
            title: '开放能力',
            titleDesc: '领先的手机软硬件能力聚合，与开发者共享',

            deviceTitle: '设备管控',
            deviceDdesc: '主要设备硬件灵活管控',
            wifi: 'WIFI',
            bluetooth: 'Bluetooth',
            VPN: 'VPN',
            NFC: 'NFC',
            GPS: 'GPS',
            camera: '相机',
            microphone: '麦克',
            SDCard: 'SD card',
            fingerprintSensor: '传感器',
            USB: 'USB',
            dataNetwork: '网络',
            OTG: 'OTG',
            screenshot: '截屏',
            accelerometer: '加速度传感器',
            debug: 'DEBUG',

            appTitle: '应用管理',
            appDdesc: '强大应用管理能力，自由掌控应用安装与使用',
            app1: '应用安装黑白名单',
            app2: '应用运行黑白名单',
            app3: '应用权限管理',
            app4: '应用进程防杀',
            app5: '远程静默安装与卸载',
            app6: '网址与域名黑白名单',

            dataTitle: '数据与安全',
            dataDdesc: '设备和数据安全信息管控与防泄密',
            data1: '防止ROOT',
            data2: '防止刷机',
            data3: '远程设备锁定',
            data4: '远程数据擦除',
            data5: '远程定位',
            data6: '全局敏感词监测',

            callTitle: '通信管控',
            callDesc: '通话与信息实时监控与记录',
            call1: '自动通话录音',
            call2: '业务联系人号码隐藏',
            call3: '发送与接收短信控制',
            call4: '呼入与呼出通话控制',

            customTitle: '个性化定制',
            customDesc: '专业定制，凸显行业气质',
            custom1: '定制开机动画',
            custom2: '定制系统',
            custom3: '定制手机主题',
            custom4: '应用预装',
            custom5: '企业标识铭刻',
            more: '* 了解更多管控信息请与我们联系',
        },
        // 应用场景
        scenarios: {
            title: '应用场景',
            titleDesc: '聚焦行业解决方案，发现行业新价值，更多场景等你创造',

            govTitle: '政务信息化',
            govDesc: '面向政企，开放数据加密，访问控制，传输加密，网络安全防护，管理运维安全等安全管理服务，为政务提供云安全和云运维保障。',

            marketSalesTitle: '市场销售',
            marketSalesDesc:
                '覆盖业务销售场景，实现客户号码隐藏，通话录音，员工定位等管控行为，保护客户信息，与公司数据安全。',

            smartHospitalTitle: '智慧医院',
            smartHospitalDesc:
                '为推进医疗信息化，通过信息下发，云定位，一键呼叫，信息安全传输等远程的调配医疗资源，实现院间协同，提升医疗服务水平。',

            educationalTitle: '教务信息化',
            educationalDesc:
                '面向教师，以提升家校联系，师生关系为导向，提高教学教务水平为目的，管控服务信息，监督家校沟通，实现云端教研等，全面提升教学水平。',

            smartOfficeTitle: '智慧办公',
            smartOfficeDesc:
                "针对企业外部联系，内部沟通。协同办公，移动办公等场景，深度管控电话行为，应用行为，设备互联网行为等，云端配置设备基础软硬件设置。",
        },

        // 优势
        advantage: {
            title: '我们的优势',
            titleDesc: '卓越品牌，专业技术，与你共创更具竞争力的价值',
            title1: '业界领先的',
            text1: 'ROM定制能力',
            title2: '成熟可信赖的',
            text2: '服务系统支撑',
            title3: '易用便捷的',
            text3: '开发工具包',
            title4: '丰富且开放的',
            text4: '产品生态',
            title5: '卓越的',
            text5: '品牌影响力',
        },

        // 步骤

        process: {
            title: '接入流程',
            titleDesc: '提供丰富行业解决方案，结合核心场景快速接入开放能力',
            step1: '完成开发者认证',
            step2: '下载SDK和ROM并集成至方案中',
            step3: '开发方案',
            step4: '提交方案',
            step5: '集成至ROM',
        },

        partners: {
            title: '合作伙伴',
            titleDesc: '携手共赢，卓越，永不止步',
        },
    },
};


export default message;
