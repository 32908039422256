export function initContentData(vm) {
    // 当语言切换时，更新页面的data
    vm.opennessData = {
        title: vm.$t('openness.title'),
        subTitle: vm.$t('openness.titleDesc'),
        more: vm.$t('openness.more'),
        data: [
            {
                title: vm.$t('openness.deviceTitle'),
                desc: vm.$t('openness.deviceDdesc'),
                className: '',
                icon: '&#xe65f;',
                list: [
                    [
                        vm.$t('openness.wifi'),
                        vm.$t('openness.bluetooth'),
                        vm.$t('openness.VPN'),
                        vm.$t('openness.NFC'),
                        vm.$t('openness.GPS'),
                    ],
                    [
                        vm.$t('openness.camera'),
                        vm.$t('openness.microphone'),
                        vm.$t('openness.SDCard'),
                        vm.$t('openness.fingerprintSensor'),
                        vm.$t('openness.USB'),
                    ],
                    [
                        vm.$t('openness.dataNetwork'),
                        vm.$t('openness.OTG'),
                        vm.$t('openness.screenshot'),
                        vm.$t('openness.accelerometer'),
                        vm.$t('openness.debug'),
                    ],
                ],
            },
            {
                title: vm.$t('openness.appTitle'),
                desc: vm.$t('openness.appDdesc'),
                className: 'w310',
                icon: '&#xe659;',
                list: [
                    [vm.$t('openness.app1'), vm.$t('openness.app2')],
                    [vm.$t('openness.app3'), vm.$t('openness.app4'), vm.$t('openness.app5')],
                    [vm.$t('openness.app6')],
                ],
            },
            {
                title: vm.$t('openness.dataTitle'),
                desc: vm.$t('openness.dataDdesc'),
                className: 'w250',
                icon: '&#xe65b;',
                list: [
                    [vm.$t('openness.data1'), vm.$t('openness.data2'), vm.$t('openness.data3')],
                    [vm.$t('openness.data4'), vm.$t('openness.data5'), vm.$t('openness.data6')],
                ],
            },
            {
                title: vm.$t('openness.callTitle'),
                desc: vm.$t('openness.callDesc'),
                className: 'w400',
                icon: '&#xe65a;',
                list: [
                    [vm.$t('openness.call1'), vm.$t('openness.call2')],
                    [vm.$t('openness.call3'), vm.$t('openness.call4')],
                ],
            },
            {
                title: vm.$t('openness.customTitle'),
                desc: vm.$t('openness.customDesc'),
                className: 'w203',
                icon: '&#xe658;',
                list: [
                    [vm.$t('openness.custom1'), vm.$t('openness.custom2')],
                    [vm.$t('openness.custom3'), vm.$t('openness.custom4'), vm.$t('openness.custom5')],
                ],
            },
        ],
    };

    vm.scenarios = {
        title: vm.$t('scenarios.title'),
        subTitle: vm.$t('scenarios.titleDesc'),
        data: [
            {
                iconClass: 'icon-zhengwu',
                title: vm.$t('scenarios.govTitle'),
                text: vm.$t('scenarios.govDesc'),
            },
            {
                iconClass: 'icon-shichangxiaoshou',
                title: vm.$t('scenarios.marketSalesTitle'),
                text: vm.$t('scenarios.marketSalesDesc'),
            },
            {
                iconClass: 'icon-zhihuiyiyuan',
                title: vm.$t('scenarios.smartHospitalTitle'),
                text: vm.$t('scenarios.smartHospitalDesc'),
            },
            {
                iconClass: 'icon-jiaoyuxinxihua',
                title: vm.$t('scenarios.educationalTitle'),
                text: vm.$t('scenarios.educationalDesc'),
            },
            {
                iconClass: 'icon-zhihuibangong',
                title: vm.$t('scenarios.smartOfficeTitle'),
                text: vm.$t('scenarios.smartOfficeDesc'),
            },
        ],
    };

    vm.tccantage = {
        title: vm.$t('advantage.title'),
        subTitle: vm.$t('advantage.titleDesc'),

        data: [
            {
                icon: '&#xe64f;',
                title: vm.$t('advantage.title1'),
                text: vm.$t('advantage.text1'),
            },
            {
                icon: '&#xe650;',
                title: vm.$t('advantage.title2'),
                text: vm.$t('advantage.text2'),
            },
            {
                icon: '&#xe64d;',
                title: vm.$t('advantage.title3'),
                text: vm.$t('advantage.text3'),
            },
            {
                icon: '&#xe64c;',
                title: vm.$t('advantage.title4'),
                text: vm.$t('advantage.text4'),
            },
            {
                icon: '&#xe64b;',
                title: vm.$t('advantage.title5'),
                text: vm.$t('advantage.text5'),
            },
        ],
    };

    vm.process = {
        title: vm.$t('process.title'),
        subTitle: vm.$t('process.titleDesc'),
        data: [
            {
                text: vm.$t('process.step1'),
                icon: '&#xe645;',
            },
            {
                text: vm.$t('process.step2'),
                icon: '&#xe649;',
            },
            {
                text: vm.$t('process.step3'),
                icon: '&#xe647;',
            },
            {
                text: vm.$t('process.step4'),
                icon: '&#xe648;',
            },
            {
                text: vm.$t('process.step5'),
                icon: '&#xe64a;',
            },
        ],
    };

    vm.partners = {
        title: vm.$t('partners.title'),
        subTitle: vm.$t('partners.titleDesc'),
        
    };
}
